<template>
  <button :type="type" class="btn" :class="classObject" @click="handleClick">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonComponent',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    classObject() {
      return [
        this.variant,
        { 'qt-loader qt-loader-mini qt-loader-right': this.loading },
        { 'btn-block': this.isBlock },
      ];
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  line-height: 26px;
  font-size: 0.875rem;
  padding: 6px 24px;
  transition: 0.5s;
  border-radius: 8px;
  font-weight: 500;

  &:hover {
    filter: brightness(70%);
  }

  &.primary {
    background-color: var(--clr-yup-purple);
    color: #fff;
  }

  &.secondary {
    background-color: #fff;
    border-color: var(--clr-yup-purple);
    color: var(--clr-yup-purple);
  }

  &.tertiary {
    background-color: transparent;
    color: var(--clr-yup-purple);
  }

  .material-symbols-outlined {
    display: inline-block;
    vertical-align: text-bottom;
    font-size: 22px;
  }

  .material-icons-outlined {
    display: inline-block;
    vertical-align: text-bottom;
    font-size: 22px;
  }
}

.register {
  .btn {
    margin-bottom: 26px;
  }
}
</style>
